<template>
  <div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      data-cy="loadingBar"
    />
    <v-container v-else-if="user && user.physician && user.physician.length">
      <v-row v-if="!readonly">
        <v-col>
          <select-target-speciality
            v-model="user.physician[0].completedTargetSpecialities"
            multiple
            label="Facharzttitel (Mehrfachauswahl möglich)"
            data-cy="specialitySelect"
          />
        </v-col>
        <v-col cols="auto">
          <app-alert
            class="mt-3"
            alert="training_authorisation"
          >
            <div class="text-subtitle-1">Meine Weiterbildungsermächtigung</div>
            <div class="text-body-2 font--text">
              Fügen Sie hier Ihren Facharzttitel sowie Ihre Weiterbildungsermächtigung/en jeweils
              mit Jahr der WBO, dem Startdatum Ihrer Befugnis und der möglichen Weiterbildungsdauer
              ein.
            </div>
          </app-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list
            data-cy="items"
            class="py-0"
          >
            <template
              v-for="(completedSpeciality, index) in user.physician[0].completed_specialities"
              :key="index"
            >
              <v-list-item
                lines="two"
                class="px-1"
              >
                <v-list-item-title>
                  {{ completedSpeciality.speciality_id.name }} -
                  {{ completedSpeciality.authorisation_months }} Monate
                </v-list-item-title>
                <v-list-item-subtitle>
                  Befugnis ab {{ formatDate(completedSpeciality.authorisation_since) }} - WBO
                  {{ completedSpeciality.authorisation_regulation_year }}
                </v-list-item-subtitle>
                <template #append>
                  <v-btn
                    v-if="!readonly"
                    icon
                    variant="flat"
                    data-cy="edit"
                    @click="setEditCompletedSpeciality(completedSpeciality)"
                  >
                    <v-icon color="primary"> mdi-pencil </v-icon>
                  </v-btn>
                </template>
              </v-list-item>
              <v-divider
                v-if="index < completedSpeciality.length - 1"
                :key="'divider-' + index"
              />
            </template>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-if="!readonly">
        <v-col class="text-center">
          <v-btn
            color="primary"
            variant="outlined"
            data-cy="addAuthorisation"
            @click="setEditCompletedSpeciality({ speciality_id: {} })"
          >
            Weiterbildungsermächtigung hinzufügen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      :model-value="editCompletedSpeciality !== null"
      :fullscreen="$vuetify.display.mobile"
      :scrim="false"
      transition="dialog-bottom-transition"
      height="400"
      width="70%"
      @click:outside="setEditCompletedSpeciality(null)"
    >
      <v-card
        v-if="editCompletedSpeciality !== null"
        data-cy="dialogBox"
      >
        <v-toolbar
          color="white"
          flat
        >
          <v-btn
            icon
            @click="setEditCompletedSpeciality(null)"
          >
            <v-icon data-cy="closeDialogBox"> mdi-close </v-icon>
          </v-btn>
          <v-toolbar-title data-cy="dialogBoxTitle">
            <v-toolbar-title>
              Weiterbildungsermächtigung
              {{
                editCompletedSpeciality && editCompletedSpeciality.id ? 'editieren' : 'hinzufügen'
              }}
            </v-toolbar-title>
          </v-toolbar-title>
        </v-toolbar>
        <v-container class="py-10 px-5">
          <v-row>
            <v-col
              cols="12"
              md="8"
              class="pb-0"
            >
              <select-speciality
                v-model="editCompletedSpeciality.speciality_id.id"
                label="Fachrichtung"
                data-cy="chooseEducation"
                variant="outlined"
                density="compact"
                :error="v$.editCompletedSpeciality.speciality_id.id.$error"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="mt-0 pb-0"
            >
              <v-text-field
                v-model="editCompletedSpeciality.authorisation_regulation_year"
                density="compact"
                label="Jahr der WBO"
                data-cy="WBO"
                type="number"
                min="1900"
                max="2100"
                variant="outlined"
                :error="v$.editCompletedSpeciality.authorisation_regulation_year.$error"
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="pb-0"
            >
              <v-text-field
                v-model="editCompletedSpeciality.authorisation_since"
                type="date"
                label="Befugnis ab"
                variant="outlined"
                data-cy="authorisation_since"
                density="compact"
                min="1900-01-01"
                max="2100-12-31"
                :error="v$.editCompletedSpeciality.authorisation_since.$error"
              />
            </v-col>
            <!--v-col cols="12" md="4" class="pb-0">
                  <v-text-field
                    v-model="editCompletedSpeciality.authorisation_until"
                    type="date"
                    label="Befugnis bis"
                    outlined
                    data-cy="authorisation_until"
                    dense
                    :error="v$.editCompletedSpeciality.authorisation_until.$error"
                  />
                </v-col-->
            <v-col
              cols="12"
              md="4"
              class="mt-0 pb-0"
            >
              <v-text-field
                v-model="editCompletedSpeciality.authorisation_months"
                type="number"
                label="Max. Weiterbildungsdauer"
                variant="outlined"
                suffix="Monate"
                data-cy="authorisation_months"
                density="compact"
                min="1"
                max="120"
                :error="v$.editCompletedSpeciality.authorisation_months.$error"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="true">
              <v-btn
                v-if="editCompletedSpeciality.id"
                variant="outlined"
                color="error-darken-1"
                data-cy="deleteAuthorisation"
                @click="removeTrainingAuthorisation(editCompletedSpeciality.id)"
              >
                Löschen
              </v-btn>
              <v-btn
                v-else
                variant="outlined"
                color="error-darken-1"
                data-cy="cancelAuthorisation"
                @click="editCompletedSpeciality = null"
              >
                Abbrechen
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn
                variant="outlined"
                class="mr-2"
                color="primary"
                data-cy="saveAuthorisation"
                @click="saveTrainingAuthorisation"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required, minValue, maxValue } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      user: {},
      editCompletedSpeciality: null,
      loading: false,
      dialog: false,
    }
  },
  async mounted() {
    await this.loadData()
    this.$watch(
      'user.physician.0.completedTargetSpecialities',
      this.saveCompletedTargetSpecialities,
      { deep: true }
    )
  },
  validations() {
    const validations = {
      editCompletedSpeciality: {
        speciality_id: { id: { required } },
        authorisation_months: { required, minValue: minValue(1), maxValue: maxValue(120) },
        authorisation_regulation_year: {
          required,
          minValue: minValue(1900),
          maxValue: maxValue(2100),
        },
        authorisation_since: { required },
        // authorisation_until: { required }
      },
    }
    return validations
  },
  methods: {
    async loadData() {
      this.loading = true
      await this.getPhysician(this.userId)
      const response = await this.$cms.request(
        this.$readUser(this.userId, {
          fields: [
            'id',
            'physician.id',
            'physician.completed_target_specialities.id',
            'physician.completed_target_specialities.target_speciality_id',
            'physician.completed_specialities.id',
            'physician.completed_specialities.speciality_id.id',
            'physician.completed_specialities.speciality_id.name',
            'physician.completed_specialities.authorisation_months',
            'physician.completed_specialities.authorisation_regulation_year',
            'physician.completed_specialities.authorisation_since',
            'physician.completed_specialities.authorisation_until',
          ],
        })
      )
      let physician = response.physician
      if (physician && physician.length) {
        physician[0].completedTargetSpecialities = physician[0].completed_target_specialities.map(
          (cts) => cts.target_speciality_id
        )
      } else {
        physician = [{ completed_specialities: [] }]
      }
      this.user = {
        ...response,
        physician,
      }
      this.loading = false
    },
    async saveTrainingAuthorisation() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        const fields = [
          'id',
          'speciality_id.id',
          'speciality_id.name',
          'authorisation_months',
          'authorisation_regulation_year',
          'authorisation_since',
          'authorisation_until',
        ]
        const completedSpeciality = {
          physician_id: this.user.physician[0].id,
          speciality_id: this.editCompletedSpeciality.speciality_id.id,
          authorisation_months: this.editCompletedSpeciality.authorisation_months,
          authorisation_regulation_year: this.editCompletedSpeciality.authorisation_regulation_year,
          authorisation_since: this.editCompletedSpeciality.authorisation_since,
          authorisation_until: this.editCompletedSpeciality.authorisation_until,
        }
        if (this.editCompletedSpeciality.id) {
          await this.$cms.request(
            this.$updateItem(
              'physician_speciality',
              this.editCompletedSpeciality.id,
              completedSpeciality,
              { fields }
            )
          )
        } else {
          await this.$cms.request(
            this.$createItem('physician_speciality', completedSpeciality, { fields })
          )
        }
        this.editCompletedSpeciality = null
        this.loadData()
      }
    },
    async removeTrainingAuthorisation(id) {
      await this.$cms.request(this.$deleteItem('physician_speciality', id))
      this.editCompletedSpeciality = null
      this.loadData()
    },
    setEditCompletedSpeciality(editCompletedSpeciality) {
      this.v$.$reset()
      this.editCompletedSpeciality = JSON.parse(JSON.stringify(editCompletedSpeciality))
    },
    async saveCompletedTargetSpecialities() {
      const user = this.user
      const completedTargetSpecialities = user.physician[0].completedTargetSpecialities.map(
        (cts) => {
          return {
            target_speciality_id: cts,
          }
        }
      )
      const physician = {
        completed_target_specialities: completedTargetSpecialities,
      }
      const query = {
        fields: [
          'id',
          'completed_target_specialities.id',
          'completed_target_specialities.target_speciality_id',
          'completed_specialities.id',
          'completed_specialities.speciality_id.id',
          'completed_specialities.speciality_id.name',
          'completed_specialities.authorisation_months',
          'completed_specialities.authorisation_regulation_year',
          'completed_specialities.authorisation_since',
          'completed_specialities.authorisation_until',
        ],
      }
      await this.$cms.request(this.$updateItem('physician', user.physician[0].id, physician, query))
    },
  },
}
</script>
